@import 'src/styles';

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal--open {
    display: block;
}

.modal__backdrop {
    width: 100%;
    height: 100%;
    background: rgba(29, 32, 35, 0.6);
}

.modal__body {
    height: auto;
    border-radius: 20px;
    background-color: var(--color-shark);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.modal__content-wrapper {
    overflow-y: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
}

.modal__body--small {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.modal__content-wrapper--small {
    padding: 20px;
}

.modal__body--medium {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.modal__content-wrapper--medium {
    padding: 20px 32px;
}

.modal__body--large {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.modal__content-wrapper--large {
    padding: 20px 32px;
}

.modal__content {
    overflow-y: auto;
    min-height: 250px;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.modal__close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 4px !important;

    & > svg {
        font-size: 24px;
    }
}

.modal__close-button--outside {
    top: 16px;
    right: -42px;
}

.modal__title {
    margin-right: 36px;
    margin-bottom: 16px;
}

.modal__title--no-margin {
    margin-right: 0;
}

@include mq-tablet-768-up {
    .modal__body--small {
        width: 440px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: unset;
    }

    .modal__body--medium {
        width: 600px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: unset;
    }
}

@include mq-desktop-1024-up {
    .modal__body--large {
        width: 960px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: unset;
    }
}

.modal__footer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
