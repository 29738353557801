@import 'src/styles';

.typography {
    display: block;
}

.typography--inline {
    display: inline-block;
}

.typography--promo-wide {
    font: var(--font-promo-wide);
}
.typography--h1-wide {
    font: var(--font-h1-wide);
}
.typography--h2-wide {
    font: var(--font-h2-wide);
}
.typography--h2-comp {
    font: var(--font-h2-comp);
}
.typography--h3-wide {
    font: var(--font-h3-wide);
}
.typography--h3-comp {
    font: var(--font-h3-comp);
}
.typography--p1-regular-comp {
    font: var(--font-p1-regular-comp);
}
.typography--p1-regular-text {
    font: var(--font-p1-regular-text);
}
.typography--p2-bold-comp {
    font: var(--font-p2-bold-comp);
}
.typography--p2-medium-comp {
    font: var(--font-p2-medium-comp);
}
.typography--p2-regular-comp {
    font: var(--font-p2-regular-comp);
}
.typography--p2-regular-text {
    font: var(--font-p2-regular-text);
}
.typography--p3-bold-comp {
    font: var(--font-p3-bold-comp);
}
.typography--p3-medium-comp {
    font: var(--font-p3-medium-comp);
}
.typography--p3-regular-comp {
    font: var(--font-p3-regular-comp);
}
.typography--p3-regular-text {
    font: var(--font-p3-regular-text);
}
.typography--caption-bold-comp {
    font: var(--font-caption-bold-comp);
}
.typography--caption-medium-comp {
    font: var(--font-caption-medium-comp);
}
.typography--caption-regular-comp {
    font: var(--font-caption-regular-comp);
}

.typography--primary {
    color: var(--color-alabaster);
}

.typography--secondary {
    color: var(--color-chateau);
}

.typography--white {
    color: var(--color-white);
}

.typography--black {
    color: var(--color-black);
}

.typography--gray {
    color: var(--color-gray);
}

.typography--link {
    text-decoration: underline;
}

@include mq-tablet-768-up {
    .typography--link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
