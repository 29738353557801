.loader {
    display: flex;
    animation: 1s linear 0s infinite normal none running spinner;
}

.loader__icon {
    font-size: 24px;
    width: 1em;
    height: 1em;

    & mask {
        mask-type: alpha;
    }
}

.loader__size--16 {
    font-size: 16px;
}

.loader__size--24 {
    font-size: 24px;
}

.loader__size--44 {
    font-size: 44px;
}

.loader__color--black {
    & g rect {
        fill: var(--color-black);
    }
}

.loader__color--white {
    & g rect {
        fill: var(--color-white);
    }
}

.loader__color--red {
    & g rect {
        fill: var(--color-mts-company);
    }
}

.loader__color--orange {
    & g rect {
        fill: var(--color-errorRed);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
