html,
body,
#__next {
    height: 100%;
    margin: 0;
    background-color: var(--color-black);
    color: var(--color-white);
}

.page-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.root-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    min-width: 360px;
    background: var(--color-black);
    overflow: hidden;
}

.root-layout:has(#news-page) {
    background-image: url('/images/news-background.svg') !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.root-layout div,
p,
span {
    overflow-wrap: break-word;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.root-layout a {
    text-decoration: none;
}

.root-layout img,
.root-layout video,
.root-layout code,
.root-layout pre {
    max-width: 100%;
}

.root-layout figure.table {
    overflow-x: auto;
}

.root-layout table,
.root-layout th,
.root-layout td {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid var(--color-chateau);
}

.root-layout ol {
    list-style: decimal;
}

.root-layout .hidden {
    display: none !important;
}

.portal-wrapper>* {
    z-index: 1000;
}

@media (min-width: 768px) {
    .swiper-pagination {
        display: none;
    }
}

@keyframes secondary-indeterminate-scale {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
        animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }

    19.15% {
        -webkit-animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
        animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
        -webkit-transform: scaleX(0.457104);
        transform: scaleX(0.457104);
    }

    44.15% {
        -webkit-animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
        animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
        -webkit-transform: scaleX(0.72796);
        transform: scaleX(0.72796);
    }

    100% {
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }
}

@-webkit-keyframes mdc-linear-progress-primary-indeterminate-translate {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
        animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    59.15% {
        -webkit-animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
        animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
        -webkit-transform: translateX(83.67142%);
        transform: translateX(83.67142%);
    }

    100% {
        -webkit-transform: translateX(200.611057%);
        transform: translateX(200.611057%);
    }
}

@keyframes mdc-linear-progress-primary-indeterminate-translate {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
        animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    59.15% {
        -webkit-animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
        animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
        -webkit-transform: translateX(83.67142%);
        transform: translateX(83.67142%);
    }

    100% {
        -webkit-transform: translateX(200.611057%);
        transform: translateX(200.611057%);
    }
}

@-webkit-keyframes mdc-linear-progress-primary-indeterminate-scale {
    0% {
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }

    36.65% {
        -webkit-animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
        animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }

    69.15% {
        -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
        animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
        -webkit-transform: scaleX(0.661479);
        transform: scaleX(0.661479);
    }

    100% {
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }
}

@keyframes mdc-linear-progress-primary-indeterminate-scale {
    0% {
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }

    36.65% {
        -webkit-animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
        animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }

    69.15% {
        -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
        animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
        -webkit-transform: scaleX(0.661479);
        transform: scaleX(0.661479);
    }

    100% {
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }
}

@-webkit-keyframes mdc-linear-progress-secondary-indeterminate-translate {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
        animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    25% {
        -webkit-animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
        animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
        -webkit-transform: translateX(37.651913%);
        transform: translateX(37.651913%);
    }

    48.35% {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
        animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
        -webkit-transform: translateX(84.386165%);
        transform: translateX(84.386165%);
    }

    100% {
        -webkit-transform: translateX(160.277782%);
        transform: translateX(160.277782%);
    }
}

@keyframes mdc-linear-progress-secondary-indeterminate-translate {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
        animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    25% {
        -webkit-animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
        animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
        -webkit-transform: translateX(37.651913%);
        transform: translateX(37.651913%);
    }

    48.35% {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
        animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
        -webkit-transform: translateX(84.386165%);
        transform: translateX(84.386165%);
    }

    100% {
        -webkit-transform: translateX(160.277782%);
        transform: translateX(160.277782%);
    }
}

@-webkit-keyframes mdc-linear-progress-secondary-indeterminate-scale {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
        animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }

    19.15% {
        -webkit-animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
        animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
        -webkit-transform: scaleX(0.457104);
        transform: scaleX(0.457104);
    }

    44.15% {
        -webkit-animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
        animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
        -webkit-transform: scaleX(0.72796);
        transform: scaleX(0.72796);
    }

    100% {
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }
}

@keyframes mdc-linear-progress-secondary-indeterminate-scale {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
        animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }

    19.15% {
        -webkit-animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
        animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
        -webkit-transform: scaleX(0.457104);
        transform: scaleX(0.457104);
    }

    44.15% {
        -webkit-animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
        animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
        -webkit-transform: scaleX(0.72796);
        transform: scaleX(0.72796);
    }

    100% {
        -webkit-transform: scaleX(0.08);
        transform: scaleX(0.08);
    }
}

.mdc-linear-progress {
    position: fixed;
    top: 1px;
    width: 100%;
    height: 4px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    outline: 1px solid transparent;
    overflow: hidden;
    -webkit-transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    -o-transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__bar {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: none;
    animation: none;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    -o-transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__bar-inner {
    display: inline-block;
    position: absolute;
    width: 100%;
    -webkit-animation: none;
    animation: none;
    border-top: 4px solid;
}

.mdc-linear-progress__primary-bar {
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
}

.mdc-linear-progress__secondary-bar {
    visibility: hidden;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
    left: -145.166611%;
    -webkit-animation: mdc-linear-progress-primary-indeterminate-translate 2s infinite linear;
    animation: mdc-linear-progress-primary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar>.mdc-linear-progress__bar-inner {
    -webkit-animation: mdc-linear-progress-primary-indeterminate-scale 2s infinite linear;
    animation: mdc-linear-progress-primary-indeterminate-scale 2s infinite linear;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
    left: -54.888891%;
    visibility: visible;
    -webkit-animation: mdc-linear-progress-secondary-indeterminate-translate 2s infinite linear;
    animation: mdc-linear-progress-secondary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar>.mdc-linear-progress__bar-inner {
    -webkit-animation: mdc-linear-progress-secondary-indeterminate-scale 2s infinite linear;
    animation: mdc-linear-progress-secondary-indeterminate-scale 2s infinite linear;
}

.mdc-linear-progress--reversed .mdc-linear-progress__bar,
.mdc-linear-progress--reversed .mdc-linear-progress__buffer {
    right: 0;
    -webkit-transform-origin: center right;
    -ms-transform-origin: center right;
    transform-origin: center right;
}

.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
    -webkit-animation-name: mdc-linear-progress-primary-indeterminate-translate-reverse;
    animation-name: mdc-linear-progress-primary-indeterminate-translate-reverse;
}

.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
    -webkit-animation-name: mdc-linear-progress-secondary-indeterminate-translate-reverse;
    animation-name: mdc-linear-progress-secondary-indeterminate-translate-reverse;
}

.mdc-linear-progress__bar-inner {
    border-color: #FF0032;
}

.Toastify__close-button--light.Toastify__close-button {
    align-self: center;
    color: var(--color-white);
    opacity: 1;
}

.Toastify__toast-theme--light.Toastify__toast--error {
    background-color: var(--color-errorRed);
    font-weight: 400;
    font-size: 16px;
    color: var(--color-white);
    font-family: MTSCompactMedium, Arial, Helvetica, sans-serif !important;
}

.Toastify__toast-theme--light.Toastify__toast--error .Toastify__toast-icon {
    fill: var(--color-white);
}

.Toastify__toast-theme--light.Toastify__toast {
    box-shadow: 0 2px 16px rgb(0 0 0 / 50%);
    border-radius: 8px;
    padding: 10px 16px;
}

.Toastify__toast-theme--light.Toastify__toast .Toastify__toast-body {
    padding: 0;
}

.Toastify__toast-theme--light.Toastify__toast .Toastify__close-button>svg {
    width: 20px;
    height: 20px;
}

@media (max-width: 767px) {
    .prp-button {
        height: 32px !important;
    }
}