@use "sass-rem" as rem;

.page-container {
    width: 100%;
    min-width: rem.convert(360px);

    padding: 0;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.page-container--page {
    padding-top: var(--margin-basic);
    padding-bottom: rem.convert(80px);
}

@media (max-width: 768px) {
    .page-container {
        padding-left: rem.convert(20px);
        padding-right: rem.convert(20px);
        gap: var(--gap-extra-small);
    }
}
@media (min-width: 768px) {
    .page-container {
        padding-left: rem.convert(40px);
        padding-right: rem.convert(40px);
        gap: var(--gap-half);
    }
}
@media (min-width: 1024px) {
    .page-container {
        padding-left: rem.convert(60px);
        padding-right: rem.convert(60px);
    }
}
@media (min-width: 1440px) {
    .page-container {
        max-width: rem.convert(1096px);
        padding-left: 0;
        padding-right: 0;
    }
}
@media (min-width: 1920px) {
    .page-container {
        gap: var(--gap-small);
        max-width: rem.convert(1240px);
    }
}
