.button {
    outline: none;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.15s ease-in-out;
    position: relative;

    & .button__content-wrapper > svg {
        width: 1em;
        height: 1em;
    }

    &:disabled {
        background-color: rgba(#7f8c99, 0.35);
        color: var(--color-gray);
        cursor: default;
    }

    &:hover:not(:disabled) {
        transform: scale(1.02);
    }

    &:active:not(:disabled) {
        transform: scale(0.98);
    }
}

.button--full-width {
    width: 100%;
}

.button--small {
    border-radius: 6px;
    padding: 6px 8px;
    font: var(--font-p3-medium-comp);

    .button__content {
        margin: 0 4px;
    }

    & .button__content-wrapper > svg {
        font-size: 20px;
    }
}

.button--small.button--icon-only {
    padding: 6px;
}

.button--medium {
    border-radius: 8px;
    padding: 10px;
    font: var(--font-p2-medium-comp);

    .button__content {
        margin: 0 8px;
    }

    & .button__content-wrapper > svg {
        font-size: 24px;
    }
}

.button--medium.button--icon-only {
    padding: 10px;
}

.button--color-primary {
    color: var(--color-white);
    background-color: var(--color-mts-company);
}

.button--color-alternative {
    color: var(--color-black);
    background-color: var(--color-white);
}

.button--color-secondary {
    color: var(--color-white);
    background-color: rgba(#7f8c99, 0.35);
}

.button--color-ghost {
    color: var(--color-white);
    background-color: transparent;
}

.button--color-negative {
    color: var(--color-error-offer);
    background-color: rgba(#7f8c99, 0.35);
}

.button__loader--color-primary {
    & g rect {
        fill: var(--color-white) !important;
    }
}

.button__loader--color-alternative {
    & g rect {
        fill: var(--color-black) !important;
    }
}

.button__loader--color-secondary {
    & g rect {
        fill: var(--color-white) !important;
    }
}

.button__loader--color-ghost {
    & g rect {
        fill: var(--color-white) !important;
    }
}

.button__loader--color-negative {
    & g rect {
        fill: var(--color-error-offer) !important;
    }
}

.button__content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.button__content-wrapper--loading {
    opacity: 0;
}

.button__loader-wrapper {
    display: none;
}

.button__loader-wrapper--show {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
