@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
        opacity: 1;
    }
    &::-moz-placeholder {
        @content;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

.textbox {
    position: relative;
    width: 100%;
    background-color: transparent;
}

.textbox__label {
    font: var(--font-p3-regular-comp);
    display: inline-block;
    margin-bottom: 4px;
    color: var(--color-chateau);
}

.textbox__label--required {
    &:after {
        content: ' *';
        font: var(--font-p3-regular-comp);
        color: var(--color-vividTangerine);
    }
}

.textbox__label--error {
    color: var(--color-vividTangerine);
}

.textbox__input-wrapper {
    position: relative;
    width: 100%;
}

.textbox__input {
    width: 100%;
    color: var(--color-white);
    background-color: rgba(#626c77, 0.25);
    box-sizing: border-box;
    font: var(--font-p2-regular-comp);
    padding: 9px 12px;
    z-index: 2;
    border-radius: 8px;
    outline: none;
    border: 1px solid var(--color-black-gray);

    @include placeholder {
        color: var(--color-chateau);
    }

    &:hover:not(:disabled) {
        border: 1px solid var(--color-white);
    }

    &:focus {
        border: 1px solid var(--color-lightBlueberry);
    }
}

.textbox__input--error {
    &:not(:focus) {
        border: 1px solid var(--color-error-offer) !important;
    }
}

.textbox__input--clearable {
    padding-right: 40px !important;
}

.textbox__input-button {
    width: 24px;
    height: 24px;
    padding: 0;
    grid-area: icon;
    position: absolute;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    top: 10px;
    right: 12px;

    & svg {
        width: 1em;
        height: 1em;
        font-size: 24px;
        color: var(--color-text-dark-secondary);
        position: absolute;
        left: 0;
        top: 0;
    }
}

.textbox__textbox-icon {
    cursor: pointer;
    width: 1em;
    height: 1em;
    font-size: 18px;
    position: absolute;
    z-index: 1;

    top: 12px;
    right: 12px;
}

.textbox__helper-text {
    color: var(--color-chateau) !important;
    font: var(--font-caption-regular-comp);
    margin-top: 4px;
}

.textbox__helper-text--error {
    color: var(--color-vividTangerine) !important;
}
