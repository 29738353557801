@import 'src/styles';

.footer {
    width: 100%;
    background: var(--color-shark);
    padding: 32px 0;
}

.footer__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
}

.footer__titleColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0 auto;

    a {
        color: var(--color-alabaster);
        opacity: 0.5;
        font: var(--font-p3-regular-comp);
    }
}

.footer__columnRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer__column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-shrink: 1;
    gap: 8px;

    a {
        font: var(--font-p3-regular-comp);
        color: var(--color-alabaster);
        opacity: 0.5;
    }
}

.footer__link {
    color: var(--color-alabaster);
}

.footer__supportText {
    color: var(--color-alabaster);
    opacity: 0.5;
    font: var(--font-p3-regular-comp);
}

.footer__social {
    display: flex;
    justify-content: flex-start;
    gap: 16px;

    & a {
        opacity: 1;
    }

    & svg {
        display: block;
        width: 1em;
        height: 1em;
        font-size: 40px;
    }
}

.footer__title {
    font: var(--font-p3-medium-comp);
    color: var(--color-alabaster);
    opacity: 0.5;
}

.footer__supportWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__text {
    font: var(--font-p3-regular-comp);
    color: var(--color-alabaster);
    opacity: 0.5;
}

.footer__disclamer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    color: var(--color-gray) !important;
    opacity: 0.5;

    & p {
        font: var(--font-p3-regular-comp);
        color: inherit;
    }
}

.footer__phoneWrapper {
    display: flex;
    flex-direction: row;
}

.footer__middleDivider {
    display: block;
}

@include mq-phone-only {
    .footer {
        padding: 24px 0;
    }

    .footer__columnRow {
        flex-direction: column;
        gap: 24px;
    }

    .footer__logo {
        width: 238px;
        height: 28px;
    }
}

@include mq-tablet-768-up {
    .footer {
        padding: 32px 0;
    }

    .footer__logo {
        width: 208px;
        height: 24px;
    }

    .footer__titleColumn {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 3;
        gap: 24px;
    }

    .footer__title {
        margin-bottom: 8px;
    }
}

@include mq-desktop-1024-up {
    .footer__logo {
        width: 205px;
        height: 24px;
    }

    .footer__title {
        font: var(--font-p2-medium-comp);
    }

    .footer__text,
    .footer__link {
        font: var(--font-p2-regular-comp) !important;
    }

    .footer__social {
        gap: 24px;

        & svg {
            font-size: 44px;
        }
    }
}

@include mq-desktop-1440-up {
    .footer__logo {
        width: 156px;
        height: 19px;
    }

    .footer__middleDivider {
        display: none;
    }

    .footer__content {
        flex-direction: row;
    }

    .footer__titleColumn {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-shrink: 3;
        gap: 24px;
    }

    .footer__supportWrapper {
        align-items: flex-start;
    }

    .footer__phoneWrapper {
        flex-direction: column;
    }
}

@include mq-desktop-1920-up {
    .footer__logo {
        width: 222px;
        height: 28px;
    }

    .footer__phoneWrapper {
        flex-direction: row;
    }
}
