.coikiePanel {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    padding: 16px 20px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    background: var(--color-background-secondary);
    box-sizing: border-box;
    z-index: 100;
    color: var(--color-white);

    &.active {
        display: none;
    }

    @media (min-width: 768px) {
        flex-direction: row;
    }

    @media (min-width: 980px) {
        max-width: 100%;
        padding: 20px 40px;
    }

    p {
        align-self: flex-start;
        max-width: 100%;
        font: var(--font-p3-regular-comp);

        @media (min-width: 980px) {
            max-width: 700px;
        }

        @media (min-width: 1024px) {
            align-self: center;
            max-width: 800px;
        }

        @media (min-width: 1440px) {
            max-width: 830px;
        }

        @media (min-width: 1920px) {
            max-width: 938px;
        }
    }

    a {
        text-decoration: none;
        color: var(--color-white);
        opacity: 0.5;

        &:active,
        &:hover {
            color: var(--color-white);
            color: var(--color-mts-company);
        }
    }
}

.wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    @media (min-width: 980px) {
        width: calc(100% - 80px);
        max-width: 100%;
        left: 50%;
        padding: 20px 40px;
    }

    @media (min-width: 1024px) {
        width: calc(100% - 120px);
    }

    @media (min-width: 1440px) {
        max-width: 1096px;
    }

    @media (min-width: 1920px) {
        max-width: 1240px;
    }
}

.btnAgree {
    min-width: 100%;
    margin-left: 0;

    @media (min-width: 768px) {
        min-width: 135px;
        margin-left: 16px;
    }
}

.btnPolicy {
    padding: 4px 0px;
    border: 2px solid rgba(127, 140, 153, 0.35);
    border-radius: 8px;
    text-decoration: none;
    width: 100%;

    @media (min-width: 768px) {
        padding: 7px 16px;
    }

    @media (min-width: 1024px) {
        padding: 13px 32px;
    }
}

.btnPolicy,
.btnAgree {
    font: var(--font-p3-medium-comp);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;

    @media (min-width: 768px) {
        font: var(--font-p2-medium-comp);
    }
}

.btnContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 16px;
    gap: 20px;
    width: 100%;

    @media (min-width: 768px) {
        width: auto;
        align-items: center;
        margin-top: 0;
    }
}
