@use "sass-rem" as rem;

.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(#1d2023, 0.95);
    box-sizing: border-box;
    backdrop-filter: blur(1px);
    z-index: 30;
    transition: transform 0.5s ease-out;

    @media (max-width: 767px) {
        position: fixed;
        width: 100%;
        padding: 4px 0;
        background-color: rgba(#1d2023, 0.95);
        transform: translateY(-54px);
    }

    @media (min-width: 768px) {
        padding: 33px 0 28px;
        height: rem.convert(143px);
    }

    @media (min-width: 1920px) {
        padding: 33px 20px 28px;
        height: rem.convert(88px);
    }
}

.header--fixed {
    @media (max-width: 767px) {
        background-color: var(--color-shark);
        transform: none;
    }
}

.header__logo-svg {
    @media (max-width: 767px) {
        width: 110px;
        height: 26px;
    }

    @media (min-width: 768px) {
        width: 172px;
        height: 40px;
    }
}

.header__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 767px) {
        flex-direction: row;
        align-items: center;
    }

    @media (min-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 0.1fr 0.3fr;
        gap: 22px 0px;
        grid-template-areas:
            'logo . search'
            'links links links';
    }

    @media (min-width: 1920px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
}

.header__logo-container {
    display: inline-flex;
    height: 44px;
    color: var(--color-mts-company);
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    grid-area: logo;
    top: 16px;

    & a {
        display: inline-flex;
    }

    @media (min-width: 768px) {
        left: 0;
        top: 4px;
        position: relative;
        transform: translateX(0);
        margin-right: 40px;
    }

    @media (min-width: 1024px) {
        margin-right: 0;
    }

    @media (min-width: 1920px) {
        margin-right: 40px;
    }
}

.header__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font: var(--font-p2-regular-comp);
    gap: 20px;
    color: var(--color-white);
    grid-area: links;
    width: 100%;

    @media (min-width: 1920px) {
        width: auto;
        font: var(--font-p2-regular-comp);
    }
}

.header__link {
    font: var(--font-p2-regular-comp);
    color: var(--color-white);
    min-height: 44px;
    display: flex;
    align-items: center;
    transition: background 0.3s ease-in;

    @media (max-width: 767px) {
        padding: 8px 0px 8px 20px;
        text-decoration: none !important;

        &:hover {
            background: rgba(242, 243, 247, 0.2);
            border-radius: 8px;
        }
    }
}
.header__link--selected {
    color: var(--color-gray);
}

.header__links-list {
    display: none;

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.header__links-list-active {
    box-sizing: border-box;
    padding: 14px 14px;
    position: fixed;
    top: 52px;
    left: 0px;
    background: var(--color-black);
    height: 100vh;
    width: 100%;
    display: block;
    z-index: 100;

    @media (min-width: 768px) {
        padding: 0;
        position: static;
        top: auto;
        left: auto;
        background: none;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.header__burger-btn {
    background: none;
    border: none;
    cursor: pointer;
    @media (min-width: 768px) {
        display: none;
    }
}

.header__controls {
    display: flex;
    align-items: center;
    margin-left: 8px;

    @media (min-width: 768px) {
        margin-left: auto;
    }

    @media (min-width: 1024px) {
        display: none;
    }

    button {
        background: none;
        border: none;
        padding: 0;
    }
}

.header__controls-search {
    @media (min-width: 768px) {
        display: none;
    }
}

.header__controls-search {
    @media (min-width: 1024px) {
        display: none;
    }
}

.header__search-mobile-open-close-button {
    grid-area: icon;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    &:active {
        filter: opacity(0.7);
    }

    @media (min-width: 1024px) {
        display: none;
    }
}

.header__mobile-stub {
    width: 100%;
    height: 52px;
    background-color: var(--color-black);

    @media (min-width: 768px) {
        display: none;
    }
}

.header__profile-container {
    display: flex;
    align-items: center;
    gap: 24px;
}

.header__favorites-link {
    display: flex;

    @media (min-width: 768px) {
        display: none;
    }
}

.header__favorites-btn {
    &[class*='favorites-heart'] {
        display: none;

        @media (min-width: 768px) {
            display: flex;
        }
    }
}

.header__favorites-counter {
    margin-left: 8px;
}
