@import 'src/styles';

.confirm-textfield {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.confirm-textfield__input-wrapper {
    display: flex;
    gap: 24px;
}

.confirm-textfield__input-box {
    width: 42px;
    height: 44px;
    background: rgba(#626c77, 0.25);
    border: 1px solid var(--color-black-gray);
    border-radius: 8px;
    display: flex;
    justify-content: center;

    &:hover {
        border: 1px solid var(--color-white);
    }

    &:focus-within {
        border: 1px solid var(--color-lightBlueberry);
    }
}

.confirm-textfield__input {
    background-color: transparent;
    color: var(--color-white);
    outline: none;
    border: none;
    width: 10px;
}

@include mq-phone-only {
    .confirm-textfield__input-box:hover {
        border: 1px solid var(--color-black-gray);
    }
}
