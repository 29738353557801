@import 'src/styles';

.subscription-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.subscription-modal__title-container {
    width: 100%;
    text-align: start;
    margin-bottom: 8px;
}

.subscription-modal__title {
    margin-bottom: 8px;
}

.subscription-modal__input-code {
    text-align: center;
}

.subscription-modal__no-code {
    margin-top: 16px;
}

.subscription-modal__timer {
    line-height: 44px;
}

.subscription-modal__pers-data {
    text-align: start;
}

.subscription-modal__pers-data-link {
    color: var(--color-lightBlueberry);

    &:hover {
        text-decoration: underline;
    }
}

@include mq-tablet-768-up {
    .subscription-modal__title-container {
        text-align: center;
    }

    .subscription-modal__pers-data {
        text-align: center;
    }
}
