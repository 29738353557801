:root {
    --font-family: 'MTSCompactMedium, MTSCompactRegular, Arial, Helvetica, sans-serif';

    --color-mts-company: #FF0032;
    --color-mts-company-darken: #cc050f;

    --color-alabaster: #FAFAFA;
    --color-discount-back: #C51345;
    --color-text-dark-secondary: #969FA8;
    --color-balticSea: #2E2B32;
    --color-shark: #1D2023;
    --color-error-offer: #FFA080;
    --color-dlc: #26CD58;
    --color-pre-order: #FAD67D;
    --color-ebonyClay: #2F3445;
    --color-errorRed: #f95721;
    --color-linkWater: #e2e5eb;
    --color-lightBlueberry: #45B6FC;
    --color-normalBlueberry: #0097fd;
    --color-darkBlueberry: #0077db;
    --color-greyChateau: #9198a0;
    --color-chateau: #969FA8;
    --color-vividTangerine: #FFA080;
    --color-dark-inactive-tabbar: #58616B;
    --color-gray: #626C77;
    --color-dark-gray: #7F8C99;
    --color-black-gray: #3c4349;
    --color-dark-black-gray: #313638;
    --color-text-light-positive: #12B23F;

    --color-white: #FFFFFF;
    --color-black: #000000;

    --color-star-5: #26CD58;
    --color-star-4: #74DF8B;
    --color-star-3: #FAD67D;
    --color-star-2: #FAC031;
    --color-star-1: #F95721;

    --color-background-secondary: #2c3135;
    --color-background-standard: rgba(127, 140, 153, 0.35);
    --color-background-light: rgba(98, 108, 119, 0.25);

    --gap: 64px;
    --gap-half: 32px;
    --gap-small: 40px;
    --gap-extra-small: 24px;

    --margin-basic: 40px;
    --margin-small: 32px;
    --margin-extra-small: 16px;
    --margin-medium: 24px;

    --font-promo-wide: 500 44px/44px 'MTSWideMedium';

    --font-h1-wide: 500 32px/36px 'MTSWideMedium';
    --font-h2-wide: 500 24px/28px 'MTSWideMedium';
    --font-h2-comp: 500 24px/28px 'MTSCompactMedium';
    --font-h3-wide: 500 20px/24px 'MTSWideMedium';
    --font-h3-comp: 500 20px/24px 'MTSCompactMedium';
    --font-h4-wide: 500 17px/24px 'MTSWideMedium';

    --font-p1-regular-comp: 400 20px/28px 'MTSCompactRegular';
    --font-p1-regular-text: 400 20px/28px 'MTSTextRegular';
    --font-p2-bold-comp: 700 17px/24px 'MTSCompactBold';
    --font-p2-medium-comp: 500 17px/24px 'MTSCompactMedium';
    --font-p2-regular-comp: 400 17px/24px 'MTSCompactRegular';
    --font-p2-regular-text: 400 17px/24px 'MTSTextRegular';
    --font-p3-bold-comp: 700 14px/20px 'MTSCompactBold';
    --font-p3-medium-comp: 500 14px/20px 'MTSCompactMedium';
    --font-p3-regular-comp: 400 14px/20px 'MTSCompactRegular';
    --font-p3-regular-text: 400 14px/20px 'MTSTextRegular';

    --font-caption-bold-comp: 700 12px/16px 'MTSCompactBold';
    --font-caption-medium-comp: 500 12px/16px 'MTSCompactMedium';
    --font-caption-regular-comp: 400 12px/16px 'MTSCompactRegular';

    --width-vertical-big: 100%;
    --width-horizontal-big: 100%;
    --width-horizontal-small: 100%;
}

@media screen and (max-width: 767px) {
    :root {
        --width-vertical-big: 236px;
        --height-vertical-big: 304px;

        --width-horizontal-big: 238px;
        --height-horizontal-big: 176px;

        --width-horizontal-small: 232px;
        --height-horizontal-small: 170px;
    }
}

@media screen and (min-width: 768px) {
    :root {
        --width-vertical-big: 196px;
        --height-vertical-big: 291px;

        --width-horizontal-big: 280px;
        --height-horizontal-big: 162px;

        --width-horizontal-small: 124px;
        --height-horizontal-small: 91px;
    }
}

@media screen and (min-width: 1024px) {
    :root {
        --width-vertical-big: 202px;
        --height-vertical-big: 260px;

        --width-horizontal-big: 280px;
        --height-horizontal-big: 162px;

        --width-horizontal-small: 124px;
        --height-horizontal-small: 91px;
    }
}

@media screen and (min-width: 1440px) {
    :root {
        --width-vertical-big: 250px;
        --height-vertical-big: 321px;

        --width-horizontal-big: 344px;
        --height-horizontal-big: 200px;

        --width-horizontal-small: 156px;
        --height-horizontal-small: 114px;
    }
}

@media screen and (min-width: 1920px) {
    :root {
        --width-vertical-big: 285px;
        --height-vertical-big: 366px;

        --width-horizontal-big: 392px;
        --height-horizontal-big: 228px;

        --width-horizontal-small: 180px;
        --height-horizontal-small: 132px;
    }
}